import React from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { ThemeProvider } from 'next-themes';
import { getCookie, setCookie } from 'cookies-next';
import { cookieOptions, ECookieKeys } from 'utils/cookie';
import AppProvider from 'contexts/app/store';
import AuthUserProvider from 'contexts/authUser/store';
import { Hydrate, QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '../styles/globals.css';
import AuthorizedProviderContainer from 'containers/AuthorizedProvider';
import { noIndex } from 'utils/SSR/hidePageOnProduction';
import LoaderOverlayProvider from 'contexts/loaderOverlay/store';
import { Inter } from 'next/font/google';
import { useRouter } from 'next/router';
import facebookService from 'utils/facebook';
import AppContainer from 'containers/App';
import { useGetCanonicalLinks, useGetHreflangLinks } from 'utils/seo';
import { ErrorBoundary } from '@sentry/nextjs';
import UnhandledError from 'containers/UnhandledError';

export const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-inter'
});

const App = ({ Component, pageProps }: AppProps) => {
  const { lang, token, promotedReferralCampaignCode } = pageProps || {};
  const router = useRouter();
  const [queryClient] = React.useState(() => new QueryClient());
  const { t } = useTranslation();

  React.useEffect(() => {
    (async () => {
      const currentLang = await getCookie(ECookieKeys.LANG);
      if (lang && lang !== currentLang) {
        setCookie(ECookieKeys.LANG, lang, cookieOptions as any);
      }
    })();
  }, [lang, token]);

  React.useEffect(() => {
    const completeHandler = () => {
      facebookService.pageView();
    };

    router.events.on('routeChangeComplete', completeHandler);
    return () => {
      router.events.off('routeChangeComplete', completeHandler);
    };
  }, [router.events]);

  return (
    <>
      <ErrorBoundary fallback={<UnhandledError />}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ReactQueryDevtools initialIsOpen={false} />
            <LoaderOverlayProvider>
              <AuthUserProvider>
                <AppProvider
                  creditCard={pageProps.creditCard}
                  promotedReferralCampaignCode={promotedReferralCampaignCode}
                >
                  <AuthorizedProviderContainer>
                    <Head>
                      <title>Poinz</title>
                      <meta name="description" content={t('meta.description') as string} />
                      <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, maximum-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no"
                      />
                      <link rel="icon" href="/favicon.ico" />
                      <link rel="mask-icon" href="/mask-icon.svg" color="#171717"></link>
                      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                      <link rel="apple-touch-startup-image" href="/apple-touch-icon.png" />
                      <link rel="manifest" href="/manifest.json" />

                      <meta name="apple-mobile-web-app-title" content="Poinz" />
                      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
                      <meta name="apple-mobile-web-app-capable" content="yes" />
                      <meta property="og:title" content={t('meta.title') as string} key="title" />
                      <meta
                        property="og:description"
                        content={t('meta.description') as string}
                        key="description"
                      />
                      <meta property="og:image" content="/assets/preview-graph.jpg" key="image" />
                      {useGetHreflangLinks()}
                      {useGetCanonicalLinks()}
                      {process.env.NEXT_PUBLIC_ENVIRONMENT !== 'prod' && noIndex()}
                    </Head>
                    <ThemeProvider defaultTheme="light" attribute="class">
                      <AppContainer
                        className={inter.variable}
                        Component={Component}
                        pageProps={pageProps}
                      />
                    </ThemeProvider>
                  </AuthorizedProviderContainer>
                </AppProvider>
              </AuthUserProvider>
            </LoaderOverlayProvider>
          </Hydrate>
        </QueryClientProvider>
      </ErrorBoundary>
      <div id="recaptcha-container"></div>
    </>
  );
};

export default appWithTranslation(App);
